import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheck} from '@fortawesome/free-solid-svg-icons';

import Portal from '../../containers/Portal';

const SubmitPopup = ({status, onCloseHandler}) => {
  const [isVisible, setVisibility] = useState(status);

  useEffect(() => {
    if (status) {
      setVisibility(true);
    }
  }, [status]);

  const onClose = () => {
    setVisibility(false);
    onCloseHandler();
  };

  return (
    <Portal isVisible={isVisible}>
      <Wrapper>
        <Content>
          <Circle status={status}>
            <FontAwesomeIcon icon={status === 'SUCCESS' ? faCheck : faTimes}/>
          </Circle>
          <H2>{status === 'SUCCESS' ? 'Thank You' : 'Error.'}</H2>
          <P>{status === 'SUCCESS' ? 'Your message has been successfully submitted. We’ll get back to you very soon.' : 'Failed to send your message.'}</P>

          <CloseButton status={status} onClick={onClose}>
            OK
          </CloseButton>
        </Content>
      </Wrapper>
    </Portal>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  background: white;
  padding: 1.5rem;

  border-radius: 10px;
  box-shadow: 9px 9px 6px #00000029;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  @media screen and (min-width: 400px) {
    padding: 4rem;
  }
`;

const Circle = styled.div`
  position: absolute;
  width: 130px;
  height: 130px;
  background: ${({status}) => (status === 'SUCCESS' ? '#39b54a' : 'red')};
  border-radius: 100%;
  top: -85px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3rem;

  @media screen and (min-width: 400px) {
    width: 180px;
    height: 180px;
    font-size: 4rem;
    top: -115px;
  }
`;

const H2 = styled.h2`
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  margin-top: 2rem;
`;

const P = styled.p`
  font-size: 1.15rem;
  text-align: center;
  color: #707070;
  margin: 1rem 0;

  @media screen and (min-width: 400px) {
    margin: 2rem 0;
  }
`;

const CloseButton = styled.div`
  width: 100%;
  height: 50px;
  background: ${({status}) => (status === 'SUCCESS' ? '#39b54a' : 'red')};
  box-shadow: 3px 3px 6px #00000029;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`;

export default memo(SubmitPopup);
