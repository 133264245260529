import React, {memo} from 'react';
import styled from 'styled-components';

const LabelContainer = ({children, form, htmlFor, ...props}) => (
  <Label htmlFor={htmlFor} {...props}>
    {children}
  </Label>
);

const Label = styled.label`
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 2rem;

  p {
    width: calc(100% - 40px);
    margin: 0;
  }

  a {
    color: black;
    font-weight: bold;
  }

  @media screen and (min-width: 680px) {
    width: auto;
  }
`;

export default memo(LabelContainer);
