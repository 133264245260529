import React, {memo} from 'react';
import styled from 'styled-components';
import {Field, ErrorMessage} from 'formik';

import ErrorComponent from './ErrorComponent';
import Row from './Row';

const TextInput = ({form, field, ...props}) => <Input {...field} {...props} />;

const Text = ({type = 'text', name, placeholder, required}) => (
  <Row>
    <label htmlFor={name} style={{width: '100%', height: '100%'}}>
      <div style={{display: 'none'}}>{placeholder}</div>
      <Field id={name} type={type} name={name} placeholder={placeholder} component={TextInput} required={required}/>
    </label>
    <ErrorMessage name={name} component={ErrorComponent}/>
  </Row>
);

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 1rem;
  font-size: 1rem;
  box-sizing: border-box;
  box-shadow: 9px 9px 6px #00000029;
  border-radius: 5px;
  color: #707070;
  border: none;
  outline: none;
`;

export default memo(Text);
