import React, {memo} from 'react';
import styled from 'styled-components';
import {Field, ErrorMessage} from 'formik';

import ErrorComponent from './ErrorComponent';
import Label from './Label';
import Row from './Row';

const CheckboxInput = ({form, field, ...props}) => <Input {...field} {...props} />;

const Checkbox = ({id, name, required, children}) => (
  <Row>
    <Label htmlFor={id}>
      <Field type="checkbox" name={name} id={id} component={CheckboxInput} required={required}/>
      <p>{children}</p>
    </Label>
    <ErrorMessage name={name} component={ErrorComponent}/>
  </Row>
);

const Input = styled.input`
  -webkit-appearance: none;
  box-sizing: border-box;
  outline: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #39b54a;
  cursor: pointer;
  margin: 0;
  margin-right: 1rem;

  :checked {
    background: #39b54a;
  }

  :checked:after {
    content: '×';
    color: white;
    font-size: 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default memo(Checkbox);
