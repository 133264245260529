import React, {memo} from 'react';
import styled from 'styled-components';

const ErrorMessage = ({children}) => <Container>{children}</Container>;

const Container = styled.div`
  width: 100%;
  color: #ff9494;
  margin-top: 0.5rem;
  position: absolute;
  bottom: -1.75rem;
`;

export default memo(ErrorMessage);
