import React, {memo} from 'react';
import styled from 'styled-components';

import Row from './Row';

const SubmitButton = ({disabled, children}) => (
  <Row style={{justifyContent: 'center', margin: '1rem 0'}}>
    <Button type="submit" disabled={disabled}>
      {children}
    </Button>
  </Row>
);

const Button = styled.button`
  width: 100%;
  max-width: 500px;
  height: 50px;
  box-shadow: 3px 3px 6px #00000029;
  background: #39b54a;
  color: white;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default memo(SubmitButton);
