import React, {memo} from 'react';
import styled from 'styled-components';

const Row = ({style, children}) => <Container style={style}>{children}</Container>;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
  position: relative;
`;

export default memo(Row);
